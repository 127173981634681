import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './shared/error-page/error-page.component';
import { MsalGuard } from '@azure/msal-angular';
import { OrderHistoryMainComponent } from './order-history/order-history-main/order-history-main.component';
//const routes: Routes = [];

const routes: Routes = [
  { path: '', component: OrderHistoryMainComponent, canActivate: [MsalGuard] }
 /* { path: '**', component: ErrorPageComponent }, */ // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
