import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { map, takeUntil, Subject } from "rxjs";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { orderHistoryOrder } from '../../../shared/models/OrderHistoryOrder.model';


@Injectable({ providedIn: 'root' })
export class OrderChangesService {

  history_api_EndPoint = environment.orderHistoryQueryApiUrl;
  constructor(private readonly http: HttpClient) { }

  getHistoryInfo(internalOrderId: string | null = ''): Observable<any> {
    let url = this.history_api_EndPoint + 'orders/' + internalOrderId + '/fetch-order-history/internalorderid';
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'json',
      'access-control-expose-headers': 'x-pagination',
    });
    return this.http.get(url, { headers: headers })
      .pipe(map((data: any): Array<orderHistoryOrder> => {
        console.log(data);
        return data;
      },
        (error: HttpErrorResponse): HttpErrorResponse => {
          console.log(error);
          return error;
        }));
  }
}
