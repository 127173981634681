export const environment = {
  production: false,
  authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
  redirectUri: 'https://dev.hmorder-orderhistory.hmgroup.com',
  Graph_Endpoint: 'https://graph.microsoft.com/v1.0/me',
  Graph_EndPoint_Photo: 'https://graph.microsoft.com/v1.0/me/photo/$value',
 // orderHistoryQueryApiUrl: 'https://ca-hmorder-history-api-sit-001--fc64shf.greendesert-58241871.westeurope.azurecontainerapps.io/api/',
 // orderHistoryWebApiScope: 'api://9938320e-825f-48f9-a696-c6108ab382fe/Orders.Read',
  dengOrderReportApiUrl: 'https://dev-orderreport-api.azurewebsites.net/v1/orders/',
  dengOrderReportApiScope: 'https://dev-orderreport-api.az.hmgroup.com/Report.Read',
  clientId: '3e4c72d5-335d-4633-b556-97bc6b879c1a',
//  clientId: '9596b298-6949-45d8-bc5e-63a518d1465a',
  orderHistoryQueryApiUrl: 'https://dev-deng-orderhistory-api.azurewebsites.net/api/',

  orderHistoryWebApiScope: 'api://c2bd68e7-ade0-45dd-ae12-a851b9938af8/Orders.Read',

 
};

