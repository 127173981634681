export class TableData {
  uidata: UIData[] = [];
  pm: string = "";
}
export class TableArticleData {
  uidata: UIData[] = [];
  article: orderArticles = new orderArticles();
}
export class UIData {
  data: string = "";
  isSelected: boolean = false;
  cancelled: boolean = false;
  isLastArticle: boolean = false;
  isChanged: boolean = false;
}
export class orderArticles {
  article?: string | null = "";
  articleId: number = 0;
  red?: number;
  blue?: number;
  green?: number;
  isChecked: boolean = true;
}
