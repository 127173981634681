<br />
<div *ngIf="detailedView==0">
  <br /><br />
  <p-table #dt [value]="oChanges"
           dataKey="title"
           [rowHover]="true"
           [showCurrentPageReport]="true"
           [filterDelay]="0">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="Date" style=" width: 190px; padding-right: 0px; cursor: default">
          Date
        </th>
        <th pSortableColumn="Changed By" style="width:200px;padding-left:0px; cursor: default">
          Changed by
        </th>
        <th pSortableColumn=" Order Change" style=" width:auto; cursor: default; padding-left:17px;">
          Order Change
        </th>

      </tr>

    </ng-template>
    <ng-template pTemplate="body" let-oChange>
      <tr class="p-datatable-striped">

        <td style="vertical-align:top;padding-top: 20px; padding-right:0px">
          <span class="p-column-title">
            Date
          </span>
          {{ oChange.savedDate }} CET
        </td>
        <td style="vertical-align: top; padding-top: 20px; padding-left: 0px;">
          <span class="p-column-title">
            Changed By
          </span>
          <span class="image-text">
            {{ oChange.savedBy }}
          </span>
        </td>
        <td style=" vertical-align: top">
          <span class="p-column-title">
            Order Change
          </span>
          <table style=" border-collapse: collapse;">
            <tr *ngFor="let pp of oChange.properties|keyvalue: returnZero" style="border-bottom:none;">
              <td style="width: 200px; color: #3b82f6; border-bottom: none; vertical-align: top; ">
                <a (click)="onClickingOrderChange(pp.key, oChange)" style="cursor: pointer" [ngClass]="{'non-links':( pp.key === 'Status' || pp.key === 'Order Date') }"> {{pp.key}} </a>
              </td>
              <td style=" border-bottom: none; vertical-align:top;">
                {{pp.value}}
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<div *ngIf="!isDataReceived" class="history-skeleton" style="margin-top:5px">
  <hmo-history-spinner class="progress-spinner"></hmo-history-spinner>
</div>

<div *ngIf="detailedView==1">
  <app-order-change-detailed-view [detailedViewTitle]="detailedViewTitle" [selectedOrderChange]="selectedOrderChange" [orderHistoryChanges]="oChanges" (backtoOverview)="backToOverview($event)"></app-order-change-detailed-view>
</div>
<!--<div *ngIf="isDataReceived" class="history-skeleton" style="margin-top:5px">
  <div style="text-align:center"> {{errorString}}</div>
</div>-->
<p-toast [preventOpenDuplicates]="true" position ="bottom-center"></p-toast>





