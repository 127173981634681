import { Component, OnInit } from '@angular/core';
import { LayoutUserService } from '../services/layout-user.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../services/profile.service';
import { UserIdentity } from '../models/User/UserIdentity.model';
import { AuthenticationResult } from '@azure/msal-browser';
import { HttpParams } from '@angular/common/http';



@Component({
  selector: 'layout-main',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  authorized: boolean = false;
  authorizationInprogress: boolean = false;
  loginDisplay = false;
  recordTotal!: number;
  tabIndex: number = 0;
  private readonly _destroying$ = new Subject<void>();
  constructor(layoutUserService: LayoutUserService, private http: HttpClient, private readonly profileService: ProfileService,
    private readonly broadcastService: MsalBroadcastService, private readonly authService: MsalService) {
    this.authService.initialize();
    const url = window.location.href;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      const id = httpParams.get("id");
      console.log(id);
    }
  }

  ngOnInit(): void {

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
      });

    //this.broadcastService.inProgress$
    //  .pipe(
    //    filter((status: InteractionStatus) => status === InteractionStatus.None),
    //    takeUntil(this._destroying$)
    //  )
    //  .subscribe(() => {

    //   this.setLoginDisplay();
    //  });
    this.authService.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        // Perform actions related to user accounts here
        console.log('Redirect success', result);
        this.setLoginDisplay();
       
      },
      error: (error) => console.log(error)
    });
  }
  

  //verifies user login is successful
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.authorizationInprogress = true;
    if (this.authService.instance.getAllAccounts().length > 0) {
      this.profileService.checkAuthorized(this.authService.instance.getAllAccounts()[0].username)
        .pipe(takeUntil(this._destroying$))
        .subscribe((result: UserIdentity) => {
          if (result) {
            this.authorized = result.isAuthorized;
          }
          else {
            this.authorized = false;
          }
          this.authorizationInprogress = false;
        }, err => {
          this.authorized = false;
          this.authorizationInprogress = false;
        });
      this.authorized = true;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }


}
