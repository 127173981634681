import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { ProfileService } from '../services/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Profile } from '../models/User/Profile';
import { map, Observable, Subject, Subscription, takeUntil, throwError } from 'rxjs';


@Component({
  selector: 'layout-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.css'],
 
})
export class TitleBarComponent implements OnInit, OnDestroy {
  getProfileSubscription!: Subscription;
  getUserAvatarSubscription!: Subscription;
  private unsubscribe$ = new Subject<void>();
  suggestions: any;
  profile!: Profile;
  profilepicURL!: SafeUrl;

  constructor(private readonly profileService: ProfileService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    //display user email
    this.getProfileSubscription = this.profileService.getProfile().subscribe(result => {
      this.profile = new Profile(result.givenName, result.userPrincipalName, result.jobTitle);
    });

    //display user profile pic
    this.getUserAvatarSubscription = this.profileService.getUserAvatar().subscribe(result => {
      var urlCreator = window.URL || window.webkitURL;
      this.profilepicURL = (this.domSanitizer.bypassSecurityTrustUrl(
        urlCreator.createObjectURL(result)));
    });
  }

  ngOnDestroy() {
    this.getProfileSubscription.unsubscribe();
    this.getUserAvatarSubscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
