import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { map, takeUntil, Subject } from "rxjs";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Table } from 'primeng/table';
import { PrimeNGConfig, MessageService} from 'primeng/api';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from "rxjs";
import { ReportRevision, Revisions, Reports, ReportDownloads } from '../../../shared/models/ReportRevision.model';


@Component({
  selector: 'app-order-revision',
  templateUrl: './order-revision.component.html',
  styleUrl: './order-revision.component.css',
  providers: [DatePipe, HttpClient, MessageService]
})
export class OrderRevisionComponent {
  revisions: Revisions[] = [];
  @Input() internalOrderId?: string | null = ' ';
  @ViewChild('dt')
  table!: Table;
  private unsubscribe$ = new Subject<void>();
  isDataReceived: boolean = false;
  errorString: string = "";

  report_api_EndPoint = environment.dengOrderReportApiUrl;


  constructor(private primengConfig: PrimeNGConfig, private datePipe: DatePipe, private http: HttpClient,private messageService: MessageService) {

  }
  getRevisionInfo(): Observable<ReportRevision> {
    let url = this.report_api_EndPoint + this.internalOrderId + '/versions';
    let queryParams = new HttpParams();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'json'
    });
    return this.http.get(url, { headers: headers })
      .pipe(map((data: any):ReportRevision => {
        console.log(data);
        return data;
      },
        (error: HttpErrorResponse): HttpErrorResponse => {
          console.log(error);           
          return error;
        }));
  }


  download(internalId: string, versionNumber: string, typeId: any): Observable<Array<ReportDownloads>> {
    let url = this.report_api_EndPoint + this.internalOrderId + '/versions/' + versionNumber + '/reports/downloadPath?reportTypeIds=' + typeId;
    let queryParams = new HttpParams();
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'json'
    });
    return this.http.get(url, { headers: headers })
      .pipe(map((data: any): Array<ReportDownloads> => {
        console.log(data);
        return data;
      },
        (error: HttpErrorResponse): HttpErrorResponse => {
          console.log(error);
          return error;
        }));
  }

  getReportAvailable(reports: Array<Reports>, typeId: any) {
   
    let reportAvailable = false;
    reports.forEach(report => {
      if (report.typeId == typeId)
       reportAvailable = true;
    });
    return reportAvailable;
  }   

  ngOnInit() {

    this.getRevisionInfo()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: ReportRevision) => {
        if (result) {
          let revision = result;
         
        
           
            revision.versionDetails.forEach(rev => {
              let revis = new Revisions();            
              revis.date = this.datePipe.transform(new Date(rev.publishedOn.toString().replace('T', ' ')), 'dd MMM yyyy HH:mm:ss'); 
              revis.userName = rev.publishedBy;
              revis.revisionComment = rev.comments;
              revis.internalOrderId = revision.season + revision.orderNumber;
              revis.reports = rev.reports;
              revis.versionNumber = rev.versionId;
              let links: string[] = [];
              this.download(revis.internalOrderId, rev.versionId, "1,2,4")
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((result: Array<ReportDownloads>) => {
                  if (result) {
                    let files = result;
                   
                    files.forEach(lnk => {
                      if (lnk.reportTypeId == "1")
                        revis.reportType1 = lnk.filePath;
                      if (lnk.reportTypeId == "2")
                        revis.reportType2 = lnk.filePath;
                      if (lnk.reportTypeId == "4")
                        revis.reportType3 = lnk.filePath;
});
                  }
                });
              rev.reports.forEach(rp => {
                if (rp.typeId == 1) {
                  revis.type1 = true;
                 

                }

                 
                if (rp.typeId == 2) {
                  revis.type2 = true;
                }
                 
                if (rp.typeId == 4) { revis.type4 = true; }
                  
              })
              this.revisions.push(revis);          
            
          });
          console.log(result);
 this.isDataReceived = true;
        }
      }, (error: HttpErrorResponse): void => {
         console.log("Error occured");
        this.isDataReceived = true;
        if (error.error == "The record does not exist")
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Reports does not exist for selected order. The order is either in preliminary status or the order reports for the definite order have not been sent out yet.' });
        else if (error.error.status == 400)
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'The selected order number does not exist' });
        else
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.Try refresh the page, if issue persist please contact support' });
        });

 

    this.primengConfig.ripple = true;
  }
  handleClick() {
    this.table.reset();
  }
}





