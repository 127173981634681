export class orderHistoryOrder {
  internalOrderId?: string | null = "";
  exportedBy!: string;
  savedDate!: Date;
  orderArticles!: Array<orderHistoryArticle>;
  orderPlanningMarkets!: Array<OrderHistoryPlanningMarket>;
  properties !: Map<string, string>;
  orderChanges!: Array<OrderChanges>;
  orderArticleChanges!: Array<OrderArticleChanges>;
  orderPlanningMarketArticlesChanges!: Array<OrderPlanningMarketArticlesChanges>;
  orderPlanningMarketsChanges!: Array<OrderPlanningMarketsChanges>;
 

}
export class OrderHistoryChanges {

  internalOrderId: string | null| undefined;
  savedBy: string = " ";
  savedDate: string| null  = " ";
  properties!: Map<string, string|null> | undefined;
  orderArticles: Array<orderHistoryArticle> = [];
  orderPlanningMarket!: Array<OrderHistoryPlanningMarket>;
  orderPlanningMarketArticlesChanges!: Array<OrderPlanningMarketArticlesChanges>;
  orderPlanningMarketsChanges!: Array<OrderPlanningMarketsChanges>;
  orderArticleChanges!: Array<OrderArticleChanges>;
}

export class orderHistoryArticle {
  articleId!: number;
  colourCode !: string;
  red!: number;
  green!: number;
  blue!: number;
  sortOrder!: number;
  properties !: Array<PmProperties>;
}

export class OrderChanges {
  propertyName!: string;
  description!: string;
}
export class OrderArticleChanges
 {
  propertyName!: string;
  description!: string;
}
export class OrderPlanningMarketsChanges {
  propertyName!: string;
  description!: string;
}
export class OrderPlanningMarketArticlesChanges
 {
  propertyName!: string;
  description!: string;
}
export class OrderHistoryPlanningMarket {
  planningMarketCode!: number;
  shortName!: string;
  sortOrder!: number;
  properties!: Array<PmProperties>;
  articles!: Array<OrderHistoryPlanningMarketArticle>;
  cancelled!: boolean;
}
export class OrderHistoryPlanningMarketArticle {
  articleId !: number;
  cancelled!: boolean;
   properties!: Array<ArticleProperties>;
}
export class ArticleProperties {
  propertyName!: string;
  value!: string;
  isChanged: boolean = false;
}

export class PmProperties {
  propertyName!: string;
  value!: string;
  isChanged: boolean = false;
}


