import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {ILayoutUser} from '../models/layout-user'

@Injectable({
  providedIn: 'root'
})
export abstract class LayoutUserService {
 
  constructor() { }

  abstract setCurrentLayoutUser (layoutUser: ILayoutUser) : Observable<any>;
  abstract signOut: (() => Observable<any>);

  
}

