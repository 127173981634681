import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './core/security/interceptors/auth.interceptor';
import { ErrorPageComponent } from './shared/error-page/error-page.component';
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { OrderHistoryMainComponent } from './order-history/order-history-main/order-history-main.component';
import { MatTab, MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from
  '@angular/platform-browser/animations';
import { OrderChangesComponent } from './order-history/order-changes/order-changes.component';
import { OrderRevisionComponent } from './order-history/order-revision/order-revision.component';
import { OrderChangeDetailedViewComponent } from './order-history/order-change-detailed-view/order-change-detailed-view.component';

import { TableModule } from 'primeng/table';
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    OrderHistoryMainComponent,
    OrderChangesComponent,
    OrderRevisionComponent,
    OrderChangeDetailedViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
     CoreModule,
      SharedModule,
    LayoutModule,
    HttpClientModule,
    MatTabsModule,
    BrowserAnimationsModule,
    TableModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    ButtonModule,
    BrowserAnimationsModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        redirectUri: environment.redirectUri
      },
      cache: {
        cacheLocation: 'localStorage'
      }
    }), {
      interactionType: InteractionType.Redirect,// MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']
      }
    }
      , {
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
      protectedResourceMap: new Map([
        [environment.Graph_Endpoint, ['user.read']],
        [environment.orderHistoryQueryApiUrl, [environment.orderHistoryWebApiScope]],
        [environment.dengOrderReportApiUrl, [environment.dengOrderReportApiScope]]
      ])
    }
    )
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
     {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalGuard
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
