import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { map, takeUntil, Subject, Subscribable, expand, reduce, empty, catchError, throwError } from "rxjs";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Table } from 'primeng/table';
import { PrimeNGConfig, PrimeIcons } from 'primeng/api';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from "rxjs";
import { ReportRevision } from '../../../shared/models/ReportRevision.model';
import { OrderHistoryChanges, orderHistoryArticle } from '../../../shared/models/OrderHistoryOrder.model';
import { orderArticles, UIData, TableArticleData, TableData } from '../../../shared/models/OrderHistoryDetailUI.model';
import { DetailedArtLvlTableData, DetailedArticleView, PmArticleValues, ArticleValues, DetailedPlanningLvlTableData, DetailedPmView, PmValues, DetailedArticleLvlTableData, DetailedArtView } from '../../../shared/models/OrderHistoryDetailView.model';


@Component({
  selector: 'app-order-change-detailed-view',
  templateUrl: './order-change-detailed-view.component.html',
  styleUrl: './order-change-detailed-view.component.css',
  providers: [DatePipe, HttpClient]
})
export class OrderChangeDetailedViewComponent {
  orderChanges: OrderHistoryChanges[] = [];
  oChanges: OrderHistoryChanges[] = [];
  @Input() detailedViewTitle?: string | null = ' ';
  @Input() selectedOrderChange: OrderHistoryChanges = new OrderHistoryChanges();
  @Input() orderHistoryChanges?: OrderHistoryChanges[] = [];
  @Output() backtoOverview = new EventEmitter<any>();
  @ViewChild('dt')
  table!: Table;
  articles: orderArticles[] = [];
  firstColumnCount: number = 0;

  uiarticles: orderArticles[] = [];
  planningMarkets: string[] | undefined = [];
  detailedArtLvlTableData: DetailedArtLvlTableData[] = [];
  detailedPmLvlTableData: DetailedPlanningLvlTableData[] = [];
  articleCount: number = 0;
  articleLevelChange: boolean = false;
  artpmlvlChange: boolean = false;
  pmlvlChange: boolean = false;

  /*Detailed view parameters*/
  quantity: string = 'Quantity';
  sellPrice: string = 'Sell Price';
  version: string = 'Version';
  sizeCurve: string = 'Size Curve';
  totalQuantity: string = 'Total Quantity';
  pmAveragePrize: string = 'PM Average Price';
  isw: string = 'ISW';
  tod: string = 'TOD';
  termsofdelivery: string = 'Terms of Delivery';
  packtype: string = 'Pack Type';
  retag: string = 'Retag'
  option: string = 'Option';
  colorcode: string = 'Colour Code';
  status: string = 'Status';

  pmData: TableData[] = [];
  artData: TableArticleData[] = [];
  pmHeaddata: TableData = new TableData();
  pmHeadUserdata: TableData = new TableData();
  artheaddata: TableArticleData = new TableArticleData();
  artheadUserdata: TableArticleData = new TableArticleData();
  pmArtheadData: orderArticles[] = [];
  prop: string = "";
  title: string = "";
  filt: OrderHistoryChanges[] = [];
  isDataReceived: boolean = false;


  private unsubscribe$ = new Subject<void>();
  constructor(private primengConfig: PrimeNGConfig, private datePipe: DatePipe, private http: HttpClient) {

  }
  ngOnInit() {

    this.articles = this.selectedOrderChange?.orderArticles.map(function (art) {

      let orderart = new orderArticles();
      let id = "0" + art.articleId.toString();
      if (id.length == 2)
        id = "0" + id;
      orderart.article = art.colourCode + " (" + id + ")";
      orderart.red = art.red;
      orderart.blue = art.blue;
      orderart.green = art.green;
      orderart.isChecked = true;
      orderart.articleId = art.articleId;

      return orderart;

    });

    this.uiarticles = this.articles?.filter(x => x.isChecked == true);
    this.articleCount = this.uiarticles?.length;
    if (this.articleCount % 2 == 0)
      this.firstColumnCount = this.articleCount / 2;
    else
      this.firstColumnCount = Math.ceil(this.articleCount / 2);

    this.setTable();
    this.primengConfig.ripple = true;
  }

  /*Back to overview page*/
  onClickingBackButton() {
    this.backtoOverview.emit(0);
  }
  onClickingCheckBox(art: orderArticles) {

    if (art.isChecked == false) {
      this.articles?.map(function (artt) { if (artt.article == art.article) artt.isChecked = true; });

    }
    else {

      this.articles?.map(function (artt) { if (artt.article == art.article) artt.isChecked = false; });
    }
    this.uiarticles = this.articles?.filter(x => x.isChecked == true);
    this.articleCount = this.uiarticles?.length;
    this.table.reset();
    this.setTable();

  }

  setTable() {
    let selectedDate = this.selectedOrderChange?.savedDate;
    let selectedExportedBy = this.selectedOrderChange?.savedBy;

    this.detailedArtLvlTableData = [];

    this.filt = [];

    if (this.detailedViewTitle === this.sellPrice ||
      this.detailedViewTitle === this.pmAveragePrize ||
      this.detailedViewTitle === this.totalQuantity ||
      this.detailedViewTitle === this.isw ||
      this.detailedViewTitle === this.tod ||
      this.detailedViewTitle === this.packtype ||
      this.detailedViewTitle === this.termsofdelivery ||
      this.detailedViewTitle === this.retag) {
      this.pmlvlChange = true;
      this.title = this.detailedViewTitle;
      if (this.detailedViewTitle == "Terms of Delivery")
        this.title = "Terms Of Delivery";
      else if (this.detailedViewTitle == "Sell Price")
        this.title = "SellPrice";
     
      this.orderHistoryChanges?.map( (ch) => {
        ch.orderPlanningMarketsChanges?.map( (pm) => {
          this.setFilter(pm.propertyName, ch);
        });
      });

      let detailedPmData = new DetailedPlanningLvlTableData();
      let allArticle = this.articles;
      let allPlanningMarket = this.filt[0].orderPlanningMarket?.map(function (pm) {
        return pm.shortName;
      }
      );
      this.planningMarkets = allPlanningMarket;    
      detailedPmData.detailedViewData = this.filt?.map( (ch) => {
        let detData = new DetailedPmView();
        detData.date = ch.savedDate?.toString();
        detData.exportedBy = ch.savedBy?.toString();     

        if (selectedDate == ch.savedDate?.toString() && selectedExportedBy == ch.savedBy?.toString()) {
          detData.isSelected = true;
        }
        else {
          detData.isSelected = false;
        }

        let allPm = ch.orderPlanningMarket?.map( (pm) => {
          let pmValue = new PmValues();
          pmValue.planningMarket = pm.shortName;         
          pmValue.cancelled = pm.cancelled;
          let pmarket = pm.properties?.filter(x => x.propertyName == this.prop);
          if (pmarket.length > 0) {
            pmValue.value = pmarket[0].value;
            pmValue.isChanged = pmarket[0].isChanged;
          }
          return pmValue;
        });

        detData.pmPropValues = allPlanningMarket?.map(function (pm) {
          let filt = allPm?.filter(x => x.planningMarket == pm);
          if (filt.length > 0) {
            return filt[0];
          }
          else {
            let pmArtValue = new PmValues();
            pmArtValue.planningMarket = pm;
            pmArtValue.value = " ";
            return pmArtValue;
          }
        });     
        return detData;
      });
      this.detailedPmLvlTableData.push(detailedPmData);

      let tableData: TableData[] = [];
      let pmheaddata = new TableData();
      pmheaddata.uidata = [];
      detailedPmData.detailedViewData?.map(function (det) {
        let dta = new UIData();
        dta.data = "" + det.date + "";
        dta.isSelected = det.isSelected;
        pmheaddata.uidata.push(dta);
      });
      let pmheadUserdata = new TableData();

      detailedPmData.detailedViewData?.map(function (det) {
        let dta = new UIData();
        dta.data = "" + det.exportedBy + "";
        dta.isSelected = det.isSelected;
        pmheadUserdata.uidata.push(dta);


      });
      this.pmHeadUserdata = pmheadUserdata;
      this.pmHeaddata = pmheaddata;
      this.planningMarkets?.map(function (pm) {
        let pmdata = new TableData();
        pmdata.uidata = [];
        pmdata.pm = pm;
        detailedPmData.detailedViewData?.map(function (det) {
          det.pmPropValues?.map(function (val) {
            if (val.planningMarket == pm) {
              let dta = new UIData();
              dta.data = val.value;
              dta.isSelected = det.isSelected;
              dta.cancelled = val.cancelled;
              dta.isChanged = val.isChanged;
              pmdata.uidata.push(dta);
            }

          });
        });
        tableData.push(pmdata);
      });
      this.pmData = tableData;
    }

    if (this.detailedViewTitle === this.option ||
      this.detailedViewTitle === this.colorcode
    ) {
      this.artpmlvlChange = true;
      this.title = this.detailedViewTitle;
     
      this.orderHistoryChanges?.map( (ch) => {
        ch.orderArticleChanges?.map( (art) => {
          this.setFilter(art.propertyName, ch);
        });
      });

      let detailedArtData = new DetailedArticleLvlTableData();    
      let allArticle = this.filt[0].orderArticles?.map(function (art) {
        return art.articleId;
      }
      );

      detailedArtData.detailedViewData = this.filt?.map( (ch) => {
        let detData = new DetailedArtView();
        detData.date = ch.savedDate?.toString();
        detData.exportedBy = ch.savedBy?.toString();

        if (selectedDate == ch.savedDate?.toString() && selectedExportedBy == ch.savedBy?.toString()) {
          detData.isSelected = true;
        }
        else {
          detData.isSelected = false;
        }

        let allArt = ch.orderArticles?.map( (art) => {
          let artValue = new ArticleValues();
          artValue.articleId = art.articleId.toString();
          let ar = art.properties?.filter(x => x.propertyName == this.prop);
          artValue.value = ar[0].value;
          artValue.isChanged = ar[0].isChanged;
          return artValue;
        });

        detData.artPropValues = allArticle?.map(function (art) {
          let filt = allArt?.filter(x => x.articleId == art.toString());
          if (filt.length > 0) {
            return filt[0];
          }
          else {
            let artValue = new ArticleValues();
            artValue.articleId = art.toString();
            artValue.value = " ";
            return artValue;
          }
        });
        return detData;
      });
      this.detailedArtLvlTableData.push(detailedArtData);

      let tableData: TableArticleData[] = [];
      let artheaddata = new TableArticleData();
      artheaddata.uidata = [];
      detailedArtData.detailedViewData?.map(function (det) {
        let dta = new UIData();
        dta.data = "" + det.date + "";
        dta.isSelected = det.isSelected;

        artheaddata.uidata.push(dta);


      });
      let artheadUserdata = new TableArticleData();

      detailedArtData.detailedViewData?.map(function (det) {
        let dta = new UIData();
        dta.data = "" + det.exportedBy + "";
        dta.isSelected = det.isSelected;
        artheadUserdata.uidata.push(dta);


      });
      this.artheadUserdata = artheadUserdata;
      this.artheaddata = artheaddata;
      this.articles?.map(function (art) {
        let artdata = new TableArticleData();
        artdata.uidata = [];
        artdata.article = art;
        detailedArtData.detailedViewData?.map(function (det) {
          det.artPropValues?.map(function (val) {
            if (val.articleId == art.articleId.toString()) {
              let dta = new UIData();
              dta.data = val.value;
              dta.isSelected = det.isSelected;
              dta.isChanged = val.isChanged;
              artdata.uidata.push(dta);

            }

          });
        });
        tableData.push(artdata);
      });
      this.artData = tableData;
      console.log(this.artData);
    }

    if (this.detailedViewTitle === this.quantity ||
      this.detailedViewTitle === this.version ||
      this.detailedViewTitle === this.sizeCurve

    ) {
      this.articleLevelChange = true;
      this.title = this.detailedViewTitle;
    
      this.orderHistoryChanges?.map( (ch) => {
        ch.orderPlanningMarketArticlesChanges?.map( (art) => {
          this.setFilter(art.propertyName, ch);
        });
      });

      let detailedArtData = new DetailedArtLvlTableData();
      let allArticle = this.articles;
      let allPlanningMarket = this.filt[0].orderPlanningMarket?.map(function (pm) {
        return pm.shortName;
      }
      );
      this.planningMarkets = allPlanningMarket;
      detailedArtData.detailedViewData = this.filt?.map( (ch) => {
        let detData = new DetailedArticleView();
        detData.date = ch.savedDate?.toString();
        detData.exportedBy = ch.savedBy?.toString();

        if (selectedDate == ch.savedDate?.toString() && selectedExportedBy == ch.savedBy?.toString()) {
          detData.isSelected = true;
        }
        else {
          detData.isSelected = false;
        }

        let allPm = ch.orderPlanningMarket?.map( (pm) => {
          let pmArticleValue = new PmArticleValues();
          pmArticleValue.planningMarket = pm.shortName;
          pmArticleValue.cancelled = pm.cancelled;
          let allArt = pm.articles?.map( (art) => {
            let artValue = new ArticleValues();
            artValue.articleId = art.articleId.toString();
            artValue.cancelled = art.cancelled;
            let ar = art.properties?.filter(x => x.propertyName == this.prop);
            artValue.value = ar[0].value;
            artValue.isChanged = ar[0].isChanged;

            return artValue;
          });
          pmArticleValue.articleValues = allArticle?.map(function (ar) {
            let filt = allArt?.filter(x => x.articleId == ar.articleId.toString());
            if (filt.length > 0) {
              filt[0].isSelected = ar.isChecked;
              return filt[0];
            }
            else {
              let artValue = new ArticleValues();
              artValue.articleId = ar.articleId.toString();
              artValue.value = " ";
              artValue.isSelected = ar.isChecked;
              return artValue;
            }

          }).filter(x => x.isSelected == true);
          return pmArticleValue;
        });

        detData.pmArticlePropValues = allPlanningMarket?.map(function (pm) {
          let filt = allPm?.filter(x => x.planningMarket == pm);
          if (filt.length > 0) {
            return filt[0];
          }
          else {
            let pmArtValue = new PmArticleValues();
            pmArtValue.planningMarket = pm;
            pmArtValue.articleValues = allArticle?.map(function (ar) {

              let artValue = new ArticleValues();
              artValue.articleId = ar.articleId.toString();
              artValue.value = " ";
              artValue.isSelected = ar.isChecked;
              return artValue;
            }).filter(x => x.isSelected == true);
            return pmArtValue;
          }
        });
        return detData;
      });
      this.detailedArtLvlTableData.push(detailedArtData);


      let tableData: TableData[] = [];
      let artheaddata = new TableArticleData();
      artheaddata.uidata = [];
      let artpmheadData: orderArticles[] = [];
      detailedArtData.detailedViewData?.map(function (det) {
        let dta = new UIData();
        dta.data = "" + det.date + "";
        dta.isSelected = det.isSelected;
        artheaddata.uidata.push(dta);
        allArticle?.map(function (art) {
          if (art.isChecked)
            artpmheadData.push(art);
        })

      });
      let artheadUserdata = new TableArticleData();

      detailedArtData.detailedViewData?.map(function (det) {
        let dta = new UIData();
        dta.data = "" + det.exportedBy + "";
        dta.isSelected = det.isSelected;
        artheadUserdata.uidata.push(dta);
      });


      this.artheadUserdata = artheadUserdata;
      this.artheaddata = artheaddata;
      this.pmArtheadData = artpmheadData;
      let artCount = this.articleCount;
      this.planningMarkets?.map(function (pm) {
        let pmdata = new TableData();
        pmdata.uidata = [];
        pmdata.pm = pm;

        detailedArtData.detailedViewData?.map(function (det) {
          let dataCount = 1;
          det.pmArticlePropValues?.map(function (val) {
            if (val.planningMarket == pm)
              val.articleValues?.map(function (art) {
                let dta = new UIData();
                dta.data = art.value;
                dta.isChanged = art.isChanged;
                dta.isSelected = det.isSelected;
                if (val.cancelled == true)
                  dta.cancelled = val.cancelled;
                else
                  dta.cancelled = art.cancelled;
                if (dataCount % artCount == 0)
                  dta.isLastArticle = true;
                pmdata.uidata.push(dta);
                dataCount++;
              });

          });
        });
        tableData.push(pmdata);
      });
      this.pmData = tableData;
    }
  }

  setFilter( name : string, ch : OrderHistoryChanges) {
    if (name == this.title) {
      this.filt.push(ch);
      this.prop = name;
    }
  }

}


