<div class="container">
  <div class="order-details-heading">
    <label>Order No:</label><br/>
    <label> Season:</label>
  </div>
  <div class="order-details">
    <label>{{orderNo}}</label><br/>
    <label> {{season}}</label>
  </div>
  <div style="background-color: #f5f5f5; border-bottom: 2px solid #dee2e6; padding-bottom:5px;">
    <a [ngClass]="{'custom-tab': tabIndex !=0, 'active-custom-tab': tabIndex ==0}" (click)="onClickingTab(0)" style="cursor: pointer">Order Changes</a>
    <a [ngClass]="{'custom-tab': tabIndex !=1, 'active-custom-tab': tabIndex ==1}" (click)="onClickingTab(1)" style="cursor: pointer">Order Reports</a>
    
  </div>
    <div *ngIf="tabIndex==0">
      <app-order-changes [internalOrderId]="internalOrderId"></app-order-changes>
    </div>
    <div *ngIf="tabIndex==1">
      <app-order-revision [internalOrderId]="internalOrderId"></app-order-revision>
    </div>
  
</div>

