import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Profile } from '../models/User/Profile';
import { UserIdentity } from '../models/User/UserIdentity.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  Authorization_Endpoint = environment.orderHistoryQueryApiUrl + 'Identity/auth';
  Graph_Endpoint = environment.Graph_Endpoint;
  Graph_EndPoint_Photo = environment.Graph_EndPoint_Photo;
  userIdentity!: UserIdentity ;
  constructor(private http: HttpClient) { }

  checkAuthorized(userName?: string) :Observable<UserIdentity>{
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'responseType': 'json'
    });

    this.Authorization_Endpoint = this.Authorization_Endpoint;
    return this.http.get(this.Authorization_Endpoint, { 'headers': headers })
      .pipe(map((data: any): UserIdentity => {
        this.userIdentity = data;
        return data;
      },
        (error: HttpErrorResponse): HttpErrorResponse => {
          return error
        }));
  }

  //Fetches user profilepic from Microsoft GraphAPI
  getUserAvatar() {
    return this.getImage(this.Graph_EndPoint_Photo);
  }

  getImage(imageUrl: string): Observable<Blob> {
    return this.http
      .get(imageUrl, {
        responseType: 'blob',
        headers: new HttpHeaders({ 'Content-Type': 'image/jpeg' }),
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  //Fetches user profile from Microsoft GraphAPI
  getProfile():Observable<Profile> {
    return (this.http.get<Profile>(this.Graph_Endpoint));
  }

  getUserIdentity() {
    return this.userIdentity;
  }
}
