import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { map, takeUntil, Subject } from "rxjs";
import { Table } from 'primeng/table';
import { PrimeNGConfig, MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { OrderChangesService } from '../services/order-changes.service';
import { orderHistoryOrder, orderHistoryArticle, OrderHistoryChanges } from '../../../shared/models/OrderHistoryOrder.model';


@Component({
  selector: 'app-order-changes',
  templateUrl: './order-changes.component.html',
  styleUrl: './order-changes.component.css',
  providers: [DatePipe, MessageService]
})
export class OrderChangesComponent {
  orderChanges: orderHistoryOrder[] = [];
  oChanges: OrderHistoryChanges[] = [];

  @Input() internalOrderId?: string | null = ' ';
  @ViewChild('dt')
  table!: Table;
  detailedViewTitle: string = "";
  selectedOrderChange: OrderHistoryChanges = new OrderHistoryChanges();
  detailedView: number = 0;
  isDataReceived: boolean = false;
  errorString: string = "";
  private unsubscribe$ = new Subject<void>();

 


  constructor(private primengConfig: PrimeNGConfig, private datePipe: DatePipe, private readonly orderChangesService: OrderChangesService, private messageService: MessageService) {

  }
  
  ngOnInit() {

    this.orderChangesService.getHistoryInfo(this.internalOrderId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: Array<orderHistoryOrder>) => {
        if (result) {

          this.orderChanges = result;
          this.orderChanges.sort((a, b) => new Date(b.savedDate).getTime() - new Date(a.savedDate).getTime());
          this.orderChanges.forEach(oChange => {
            let ooChange = new OrderHistoryChanges();
            ooChange.internalOrderId = this.internalOrderId;
            ooChange.savedBy = oChange.exportedBy;
            ooChange.orderArticles = oChange.orderArticles;
            ooChange.orderPlanningMarket = oChange.orderPlanningMarkets;
            ooChange.orderPlanningMarketArticlesChanges = oChange.orderPlanningMarketArticlesChanges;
            ooChange.orderPlanningMarketsChanges = oChange.orderPlanningMarketsChanges;
            ooChange.orderArticleChanges = oChange.orderArticleChanges;
            ooChange.savedDate = this.datePipe.transform(new Date(oChange.savedDate.toString().replace('T', ' ')), 'dd MMM yyyy HH:mm:ss');


            ooChange.properties = new Map<string, string>();

            let date = this.datePipe;
            oChange.orderChanges.map(function (pp) {
              if (pp.propertyName == "Order Placement Date") {

                ooChange.properties?.set("Order Date", date.transform(pp.description.substring(0, 11), 'dd MMM yyyy'));
              }
              else
                ooChange.properties?.set(pp.propertyName, pp.description);

            });

            oChange.orderArticleChanges.map(function (pp) {

              ooChange.properties?.set(pp.propertyName, pp.description);

            });

            oChange.orderPlanningMarketsChanges.map(function (pp) {

              if (pp.propertyName == "Terms Of Delivery")
                ooChange.properties?.set("Terms of Delivery", pp.description);
              else if (pp.propertyName == "SellPrice") {

                ooChange.properties?.set("Sell Price", pp.description);
              }
              else
                ooChange.properties?.set(pp.propertyName, pp.description);

             

            });

            oChange.orderPlanningMarketArticlesChanges.map(function (pp) {


              ooChange.properties?.set(pp.propertyName, pp.description);

            });



            this.oChanges.push(ooChange);
          });

          this.isDataReceived = true;
          console.log(this.orderChanges);

        }
      }, (error: HttpErrorResponse): void => {
         console.log("Error occured");
        this.isDataReceived = true;
        if (error.error != null && error.error.developerMessage == "Error raised in GetOrderHistoryQueryHandler with exception message Object reference not set to an instance of an object.")
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'The selected order number does not exist' });
        else
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong.Try refresh the page, if issue persist please contact support' });
          this.errorString = "Error loading Order. Error Message : " + error.message;
        });
    this.primengConfig.ripple = true;
  }
  handleClick() {
    this.table.reset();
  }
  returnZero() {
    return 0;
  }
  onClickingOrderChange(change: any, order: OrderHistoryChanges) {

    this.detailedViewTitle = change;
    this.selectedOrderChange = order;
    this.detailedView = 1;

  }
  backToOverview(overView: any) {
    this.detailedView = overView;
  }

}

