export class ReportRevision {
  orderId!: string;
  orderNumber!: string;
  season!: string;
  versionDetails!: Array<VersionDetails>;
}

export class VersionDetails {
  versionId!: string;
  comments!: string;
  publishedOn!: string;
  publishedBy!: string;
  reports!: Array<Reports>;
}

export class Reports {
  typeId! : number;
  typeName!: string;
  fileName!: string;
}

export class Revisions {
  date?: string | null = "";
  userName?: string = "";
  revisionComment?: string = "";
  internalOrderId: string = "";
  reports: Reports[] = [];
  versionNumber: string = "";
  type1: boolean = false;
  type2: boolean = false;
  type4: boolean = false;
  reportType1: string = "";
  reportType2: string = "";
  reportType3: string = "";
}

export class ReportDownloads {
  reportTypeId: string = "";
  filePath: string = "";
      
    }


