import { Component } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-order-history-main',
  templateUrl: './order-history-main.component.html',
  styleUrl: './order-history-main.component.css'
})
export class OrderHistoryMainComponent {
  tabIndex: number = 0;
  orderNo?: string;
  season?: string;
  internalOrderId?: string | null;
  constructor() {
    if (localStorage.getItem('tabIndex'))
      this.tabIndex = Number(localStorage.getItem('tabIndex'));
    const url = window.location.href;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      const id = httpParams.get("id");
      this.season = id?.substr(5, 1) + "-" + id?.substr(0, 4);
      this.orderNo = id?.substr(6, 6);
      this.internalOrderId = id;
    }
  }

  onClickingTab(activeTabIndex: number) {

    this.tabIndex = activeTabIndex;
    localStorage.removeItem('tabIndex');
    localStorage.setItem('tabIndex', activeTabIndex.toString());
  }
}
