<!--start of layout -->
<div class="layout-container" [ngClass]="{'explorer-height':recordTotal && recordTotal !==0 && tabIndex==0}" *ngIf="loginDisplay && authorized">
  <layout-title-bar></layout-title-bar>
  <main class="site-wrap">
    <router-outlet></router-outlet>
  </main>
</div>
<!-- End of layout -->
<!-- Start of error page -->
<div *ngIf="loginDisplay && !authorized && !authorizationInprogress" class="container" style=" display: flex; flex-direction: column; align-items: center; justify-content: center;">

  <div class="row" style="display: flex;color: #428bca; margin-top:70px; flex-direction: column; align-items: center; justify-content: center; border: 1px solid #E0E0E0; box-shadow: 0 0 10px 5px #E0E0E0 ">
   
      <h2 style="margin-top: initial; text-align: center;">Access Denied</h2>
   
   
  </div>
</div>
<!-- End of error page -->
