import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { throwIfAlreadyLoaded } from '../utilities/throwIfAlreadyLoaded';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../app/shared/shared.module';
import {MatTabsModule} from '@angular/material/tabs';

@NgModule({
  declarations: [
    TitleBarComponent,
    LayoutComponent
  ],
  exports: [LayoutComponent],
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    SharedModule,
    MatTabsModule
  ]
})
export class LayoutModule {
  constructor(@Optional() @SkipSelf() parentModule: LayoutModule) {
    throwIfAlreadyLoaded(parentModule, 'LayoutModule')
  }
}
