
<ul [ngClass]="{'art-checkbox-title': articleLevelChange, 'no-art-checkbox-title': !articleLevelChange }">
  <li style="padding-top: 0px; vertical-align: top;">
    <a class="back-button" (click)="onClickingBackButton()" style="cursor: pointer"><span ><img src="assets/images/hm-order/bluearrow.svg" class="arrow-image" /></span><span style="font-size: 14px; vertical-align:top; padding-left: 2px; color: #3b82f6;">Back to Overview </span></a>
    <br/>
    <div class="detailed-heading" style="padding-left:4px;padding-top:2px">{{detailedViewTitle}} Changes</div>
  </li>
  <li>
    <!--Article Checkbox for planning market article level changes-->
    <span *ngIf="articleLevelChange" class="articleCheckboxList">
      <ul class="articleCheckbox" style="padding-top:10px;">
        <li *ngFor="let art of articles" style="padding-bottom: 5px;">
          <input type="checkbox" (click)="onClickingCheckBox(art)" checked />
          <i class="pi pi-circle-fill color-icon"
             style="padding-left: 5px; padding-right: 3px; border-radius: 60px; color: rgb({{art.red}}, {{art.green}}, {{art.blue}});"></i>
          {{art.article}}
        </li>
      </ul>
    </span>
  </li>
</ul>
<!--Article Level Changes Table-->
<div *ngIf="articleLevelChange">
  <p-table #dt [value]="pmData"
           dataKey="title"
           [rowHover]="true"
           [showCurrentPageReport]="true"
           [filterDelay]="0"
           [resizableColumns]="true"
           [autoLayout]="true"
           [scrollable]="true" scrollHeight="10%">
    <ng-template pTemplate="header">
      <tr pFrozenRow>
        <th style=" border-bottom: none; vertical-align: bottom; padding-bottom: 0px; background-color: #f5f5f5; border-right: 1px solid #dee2e6;" [rowSpan]="3" pFrozenColumn></th>
        <th class="username date"  style="border-right: 1px solid #dee2e6;" [colSpan]="articleCount" *ngFor="let dta of artheaddata.uidata">
          {{dta.data}}
        </th>
      </tr>
      <tr pFrozenRow>
        <th  class= "username"  style="border-right: 1px solid #dee2e6;" [colSpan]="articleCount" *ngFor="let dta of artheadUserdata.uidata">
          {{dta.data}}
        </th>
      </tr>
      <tr pFrozenRow>
        <th class="username" *ngFor="let dta of pmArtheadData"  style="border-right: 1px solid #dee2e6;">
          <i class="pi pi-circle-fill color-icon"
             style="padding-left: 5px; padding-right: 3px; border-radius: 60px; color: rgb({{dta.red}}, {{dta.green}}, {{dta.blue}}); "></i>
          {{dta.article}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-detail>
      <tr class="p-datatable-striped">
        <td style="border-right: 1px solid #dee2e6; " pFrozenColumn>
          {{detail.pm}}
        </td>
        <ng-container *ngFor="let dta of detail.uidata">
          <td [ngClass]="{'selectedCol': dta.isSelected === true, 'nonSelectedCol': dta.isSelected === false, 'cancelled-art-or-pm': dta.cancelled, 'last-article': dta.isLastArticle, 'not-last-article': !dta.isLastArticle, 'changed-values': !dta.isChanged, 'cancelled-non-changed': dta.cancelled && !dta.isChanged}">
            {{dta.data}}
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
<!--PM level changes Table-->
<div *ngIf="pmlvlChange">
  <br />
  <br />
  <p-table #dt [value]="pmData"
           dataKey="title"
           [rowHover]="true"
           [showCurrentPageReport]="true"
           [filterDelay]="0"
           [resizableColumns]="true"
           [autoLayout]="true"
           [scrollable]="true" scrollHeight="10%">
    <ng-template pTemplate="header">
      <tr pFrozenRow>
        <th style="vertical-align: bottom; background-color: #f5f5f5;border-right: 1px solid #dee2e6;" [rowSpan]="2" pFrozenColumn>PM</th>
        <th class="username date"  style="border-right: 1px solid #dee2e6;" *ngFor="let dta of pmHeaddata.uidata">
          {{dta.data}}
        </th>
      </tr>
      <tr pFrozenRow>
        <th  class= "username"  style="border-right: 1px solid #dee2e6;" *ngFor="let dta of pmHeadUserdata.uidata">
          {{dta.data}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-detail>
      <tr class="p-datatable-striped">
        <td style="border-right: 1px solid #dee2e6; " pFrozenColumn>
          {{detail.pm}}
        </td>
        <ng-container *ngFor="let dta of detail.uidata">
          <td [ngClass]="{'selectedCol': dta.isSelected === true, 'nonSelectedCol': dta.isSelected === false, 'cancelled-art-or-pm': dta.cancelled,'changed-values':!dta.isChanged,'cancelled-non-changed': dta.cancelled && !dta.isChanged}">
            {{dta.data}}
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>
<!--PM Article Level Changes Table-->
<div *ngIf="artpmlvlChange">
  <br />
  <br />
  <p-table #dt [value]="artData"
           dataKey="title"
           [rowHover]="true"
           [showCurrentPageReport]="true"
           [filterDelay]="0"
           [resizableColumns]="true"
           [autoLayout]="true"
           [scrollable]="true" scrollHeight="10%">
    <ng-template pTemplate="header">
      <tr pFrozenRow>
        <th style="vertical-align: bottom; background-color: #f5f5f5; border-right: 1px solid #dee2e6;" [rowSpan]="2" pFrozenColumn>Article</th>
        <th class="username date"  style="border-right: 1px solid #dee2e6;" *ngFor="let dta of artheaddata.uidata">
          {{dta.data}}
        </th>
      </tr>
      <tr pFrozenRow>
        <th class= "username"  style="border-right: 1px solid #dee2e6;" *ngFor="let dta of artheadUserdata.uidata">
          {{dta.data}}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-detail>
      <tr class="p-datatable-striped">
        <td style="border-right: 1px solid #dee2e6; " pFrozenColumn>
          <i class="pi pi-circle-fill color-icon"
             style="padding-left: 5px; padding-right: 3px; border-radius: 60px; color: rgb({{detail.article.red}}, {{detail.article.green}}, {{detail.article.blue}}); "></i>
          {{detail.article.article}}
        </td>
        <ng-container *ngFor="let dta of detail.uidata">
          <td [ngClass]="{'selectedCol': dta.isSelected === true, 'nonSelectedCol': dta.isSelected === false, 'cancelled-art-or-pm': dta.cancelled, 'changed-values': !dta.isChanged, 'cancelled-non-changed': dta.cancelled && !dta.isChanged}">

            {{dta.data}}
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>


