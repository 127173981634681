import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LayoutUserService } from '../../../layout/services/layout-user.service';
import { delay, Observable, of } from 'rxjs';
import { ILayoutUser } from '../../../layout/models/layout-user';
import { Router } from '@angular/router';


@Injectable({providedIn: 'root'})
export class RootUserService implements LayoutUserService {

  isAuthenticated: boolean = false;

  constructor(private httpClient: HttpClient,private router: Router) { 
    this.isAuthenticated = false;
  }

  setCurrentLayoutUser(layoutUser: ILayoutUser): Observable<any> {
    //throw new Error('Method not implemented.');
    console.log('sign-in called');
    this.isAuthenticated = true;
    return of({}).pipe(delay(2000));
  }

 
  signOut(): Observable<any> {
    console.log('signout called');
    this.isAuthenticated = false;
    //this.router.navigate(['/signin']);
    return of({});
  }


    
  getAuthToken(): string | null {
    return localStorage.getItem("token");
  }
    
}

