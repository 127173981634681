 <!-- Start of header -->
 <header class="header-items">
   <div class="header-item">
     <img src="assets/images/hm-order/hm-logo.svg" class="user-image" />
     <p class="brand--text">Order History</p>
   </div>
   <div class="header-item right-header">
     <ng-container>
       <div class="user-details-block">
         <p class="user-name--text" *ngIf="profile">{{profile.userPrincipalName}}</p>
         <p class="user-sub--text" *ngIf="profile">{{profile.jobTitle}}</p>
       </div>
       <img [src]="profilepicURL" class="user-image" />
     </ng-container>

   </div>

 </header>


<!-- End of header -->
