<br/><br /><br/>
<p-table #dt [value]="revisions"
         dataKey="title"
         [rowHover]="true"
         [showCurrentPageReport]="true"
         [filterDelay]="0">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="Date" style="width: 200px;">
        Date
      </th>
      <th pSortableColumn="User name">
        User Name
      </th>
      <th pSortableColumn="Revision Comment">
        Revision Comment
      </th>
      <th pSortableColumn="Purchase Order" style="width: 130px;">
        Purchase Order
      </th>
      <th pSortableColumn="Size/Color Breakdown" style="width: 170px;">
        Size/Color Breakdown
      </th>
      <th pSortableColumn="Total Country Breakdown" style="width: 190px;">
        Total Country Breakdown
      </th>
    </tr>
     
  </ng-template>
  <ng-template pTemplate="body" let-revision>
    <tr class="p-datatable-striped">

      <td style="vertical-align: top;">
        <span class="p-column-title">
          Date
        </span>
        {{ revision.date | date: "dd MMM yyyy HH:mm:ss":"UTC+4" }} CET
      </td>
      <td style="vertical-align: top;">
        <span class="p-column-title">
          User Name
        </span>
        <span class="image-text">
          {{ revision.userName }}
        </span>
      </td>
      <td style="vertical-align: top;">
        <span class="p-column-title">
          Revision Comment
        </span>
        <span class="image-text">
          {{ revision.revisionComment }}
        </span>
      </td>
      <td style="vertical-align: top;">
        <span class="p-column-title">
          Purchase Order
        </span>
        <span class="image-text">
          <a href="{{revision.reportType1}}"  target="_blank" *ngIf="getReportAvailable(revision.reports, 1)"  id="report-link"> Open Report</a>
          <div  *ngIf="!getReportAvailable(revision.reports, 1)" style="color:#BDBDBD" > Open Report</div>
        </span>
      </td>
      <td style="vertical-align: top;">
        <span class="p-column-title">
          Size/Color Breakdown
        </span>
        <span class="image-text">
          <a href="{{revision.reportType2}}"  target="_blank" *ngIf="getReportAvailable(revision.reports, 2)"  id="report-link"> Open Report</a>
          <div *ngIf="!getReportAvailable(revision.reports, 2)"style="color:#BDBDBD" > Open Report</div>
        </span>
      </td>
      <td style="vertical-align: top;">
        <span class="p-column-title">
          Total Country Breakdown
        </span>
        <span class="image-text">
          <a href="{{revision.reportType3}}" target="_blank" *ngIf="getReportAvailable(revision.reports, 4)"  id="report-link"> Open Report</a>
          <div *ngIf="!getReportAvailable(revision.reports, 4)" style="color:#BDBDBD" > Open Report</div>
        </span>
      </td>
    </tr>
  </ng-template>
</p-table>
<div *ngIf="!isDataReceived" class="history-skeleton" style="margin-top:5px">
  <hmo-history-spinner class="progress-spinner"></hmo-history-spinner>
</div>
<p-toast [preventOpenDuplicates]="true" position="bottom-center"></p-toast>



