
export class DetailedArtLvlTableData {
  detailedViewData?: Array<DetailedArticleView> = [];
}
export class DetailedArticleView {
  date?: string | null = "";
  exportedBy?: string | null = "";
  pmArticlePropValues?: Array<PmArticleValues>;
  isSelected: boolean = false;
}


export class PmArticleValues {

  planningMarket?: string;
  articleValues?: Array<ArticleValues>;
  cancelled: boolean = false;
}

export class ArticleValues {
  articleId?: string;
  value: string = "";
  isChanged: boolean = false;
  isSelected: boolean = false;
  cancelled: boolean = false;
}

// planning market level data
export class DetailedPlanningLvlTableData {
  detailedViewData?: Array<DetailedPmView> = [];
}
export class DetailedPmView {
  date?: string | null = "";
  exportedBy?: string | null = "";
  pmPropValues: Array<PmValues> = [];
  isSelected: boolean = false;
}

export class PmValues {

  planningMarket?: string;
  value: string = " ";
  isChanged: boolean = false;
  cancelled: boolean = false;
}

// article level data
export class DetailedArticleLvlTableData {
  detailedViewData?: Array<DetailedArtView> = [];
}
export class DetailedArtView {
  date?: string | null = "";
  exportedBy?: string | null = "";
  artPropValues: Array<ArticleValues> = [];
  isSelected: boolean = false;

}
