import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from '../../utilities/throwIfAlreadyLoaded';
import { AuthGuard } from './security/auth.guard';
import { RootUserService } from './services/root-user.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RootUserService, 
    AuthGuard //,  
    //{ provide: HTTP_INTERCEPTORS,useClass: AuthInterceptor,multi: true}
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule:CoreModule)
  {
    throwIfAlreadyLoaded(parentModule,'CoreModule')
  }

 }
